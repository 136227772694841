.button {
    border: 1px solid var(--color-text);
    color: var(--color-text);
    padding: 15px 30px;
    display: inline-block;
    cursor: pointer;
    background: none;
}

.more-link {
    display: none;
}

@media (max-width: 500px) {
    .more-text {
        display: none;
    }

    .more-text.is-show {
        display: block;
    }

    .more-link {
        display: inline-block;
    }
}

.link {
    font-weight: bold;
    display: inline-block;
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
    padding-right: 25px;
    text-transform: uppercase;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        translate: 0 -50%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M11.5 15.392V6q0-.213.143-.357T12 5.5t.357.143T12.5 6v9.392l3.746-3.746q.14-.14.345-.15q.203-.01.363.15t.16.354t-.16.354l-4.389 4.388q-.242.243-.565.243t-.565-.243l-4.389-4.388q-.14-.14-.15-.344t.15-.364t.354-.16t.354.16z'/%3E%3C/svg%3E");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    &.is-on::after {
        scale: 1 -1;
    }
}

.text-center {
    text-align: center;

    a {
        font-weight: bold;
        color: inherit;
    }
}

.s-title {
    font-size: 42px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
    position: relative;
}

.s-title-center {
    font-size: 42px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
}

.s-text {
    font-size: 20px;
    margin-bottom: 50px;
    line-height: 1.7;
}

.s-text-center {
    max-width: 900px;
    margin: 20px auto;
    text-align: center;
    font-size: 20px;
    margin-bottom: 50px;
    line-height: 1.7;
    color: #32373a;
}

.s-bg {
    background: #EAE9E4 url(../images/texture.jpg) center / cover;

    @media (max-width: 640px) {
        background-image: url(../images/texture-m.jpg);
    }
}

.s-full {
    padding: 80px 20px;
}

@media (max-width: 570px) {
    .s-title,
    .s-title-center {
        font-size: 30px;
    }

    .s-text,
    .s-text-center {
        font-size: 15px;
    }
    .s-full {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}