.form {
}

.form-row {
    margin-bottom: 20px;
}

.form-row-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.form-label {
    font-size: 16px;
    text-transform: uppercase;
    display: block;
}

.form-control {
    background: #f3f3f3;
    border: 0;
    min-height: 58px;
    padding: 10px 15px;
    display: flex;
    width: 100%;
    font-size: 18px;
    font-family: inherit;

    &:focus {
        outline: none;
        box-shadow: inset 0 0 0 2px #000;
    }
}

.form-control:is(textarea) {
    min-height: 200px;
    padding: 15px;
}

.form-control.is-error {
    background: #f8d3d9;
    border-color: red;
}

.form-row:has(.is-error) .form-label {
    color: red;
}

.form-msg {
    font-weight: bold;
    color: red;
    margin-bottom: 10px;
}

.form-button {
    position: relative;

    &.is-loading {
        color: #333;
        pointer-events: none;

        &::before {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 5px solid rgba(#000, 0.3);
            border-right-color: #000;
            position: absolute;
            left: 50%;
            top: 50%;
            translate: -50% -50%;
            animation: loadingAnim 0.5s 0s infinite linear;
        }
    }
}

@keyframes loadingAnim {
    to {
        rotate: 1turn;
    }
}

@media (max-width: 1100px) {
    .form-control {
        min-height: 45px;
    }
    .form-row {
        margin-bottom: 15px;
    }
}