.hero {
    height: 870px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: #fff;
    isolation: isolate;
    gap: 30px;
    position: relative;
    padding-bottom: 150px;
    padding-left: 20px;
    padding-right: 20px;
}

.hero picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.hero::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 0;
    top: 200px;
    left: 0;
    right: 0;
    bottom: 0;
    background:
        linear-gradient(to top, rgb(0 0 0 / 0.3), transparent),
        linear-gradient(to top, rgb(128 128 128 / 0.8), transparent);
}

.hero-title,
.hero-text,
.hero-links {
    position: relative;
    z-index: 1;
}

.hero-title {
    font-size: 5.5vmax;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    margin: 0;
    word-spacing: 20px;
    text-shadow: 0 2px 10px rgba(0 0 0 / 0.4);
}

.hero-text {
    font-size: 24px;
    max-width: 820px;
    margin: 0 auto;
    text-align: center;
    text-shadow: 0 2px 5px rgba(0 0 0 / 0.4);
}

.hero-links {
    display: flex;
    gap: 20px;
}

.hero-link {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    min-width: 200px;
    text-align: center;
    text-transform: uppercase;
    background: none;
    color: #fff;
    position: relative;

    --s: 5px;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        border: 1px solid #fff;
        overflow: hidden;
        position: relative;

        clip-path: polygon(
            0 0, 100% 0,
            100% calc(50% - var(--s)),
            calc(100% - var(--s)) calc(50% - var(--s)),
            calc(100% - var(--s)) calc(50% + var(--s)),
            100% calc(50% + var(--s)),
            100% 100%,
            0 100%,
            0 calc(50% + var(--s)),
            var(--s) calc(50% + var(--s)),
            var(--s) calc(50% - var(--s)),
            0 calc(50% - var(--s))
        );
    }

    &::before, &::after {
        content: "";
        width: 20px;
        height: 1px;
        background: #fff;
        position: absolute;
        left: 0;
        top: 50%;
        translate: -50% 0;
    }
    &::after {
        left: auto;
        right: 0;
        translate: 50% 0;
    }
}

@media (max-width: 570px) {
    .hero {
        height: 670px;
        padding-bottom: 50px;
    }
    .hero-title {
        font-size: 40px;
        word-spacing: 0;
    }

    .hero-text {
        font-size: 18px;
    }

    .hero-link {
        font-size: 16px;
        min-width: 150px;
    }
}

@media (max-width: 570px) {
    .hero {
        height: calc(100svh - 70px);
    }
    .hero-title {
        font-size: 30px;
    }
    .hero-text {
        font-size: 16px;
    }
    .hero-link span {
        padding: 20px 10px;
    }
}