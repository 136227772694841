.s-contact-title {
}

.contact-address {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 30px;

    span {
        display: block;
        margin-bottom: 5px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}

.contact-address-mail {
    background-size: 20px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
    min-height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M3 19V5h18v14zm9-6.884L4 6.885V18h16V6.885zM12 11l7.692-5H4.308zM4 6.885V6v12z'/%3E%3C/svg%3E");
}

.contact-address-phone {
    background-size: 20px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
    min-height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M19.2 20q-2.702 0-5.418-1.244t-5.005-3.533q-2.27-2.289-3.523-5.021Q4 7.469 4 4.8V4h4.439l.848 4.083l-2.696 2.51q.684 1.186 1.417 2.167t1.527 1.769q.802.84 1.808 1.57t2.296 1.44l2.611-2.708l3.75.756V20z'/%3E%3C/svg%3E");
}

@media (max-width: 600px) {
    .contact-address {
        grid-template-columns: 1fr;
    }
}

