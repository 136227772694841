.header {
    background: var(--page-bg);
    position: relative;
    z-index: 100;
    position: sticky;
    top: -1px;
}

.header.is-sticky {
    box-shadow: 0 2px 20px rgba(#000, 0.2);
}

.header-container {
    display: flex;
    align-items: center;
    gap: 30px;
    max-width: 1900px;
    padding-left: 15px;
    padding-right: 15px;
}

.header-inside {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 30px;
}

.logo {
    width: 153px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--page-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    margin-bottom: -20px;

    img {
        width: calc(100% - 20px);
        aspect-ratio: 1;
    }
}

.burger {
    width: 60px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: 0;
    background: none;
    display: none;
    cursor: pointer;

    strong {
        display: none;
    }

    span {
        width: 25px;
        height: 2px;
        background: var(--color-text);
    }
}

.menu {
    font-size: 20px;
}

.menu-list {
    display: flex;
    gap: 30px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-list-link {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
        background: linear-gradient(to top, #DCE2E1 50%, transparent 50%);
    }
}

.phone {
    margin-left: auto;
    border-radius: 100px;
    height: 37px;
    padding-right: 15px;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text);
    border: 1px solid var(--color-text);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M19.95 21q-3.125 0-6.187-1.35T8.2 15.8t-3.85-5.55T3 4.05V3h5.9l.925 5.025l-2.85 2.875q.55.975 1.225 1.85t1.45 1.625q.725.725 1.588 1.388T13.1 17l2.9-2.9l5 1.025V21z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 20px;
    padding-left: 35px;

    &:hover {
        color: #fff;
        background-color: var(--color-text);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M19.95 21q-3.125 0-6.187-1.35T8.2 15.8t-3.85-5.55T3 4.05V3h5.9l.925 5.025l-2.85 2.875q.55.975 1.225 1.85t1.45 1.625q.725.725 1.588 1.388T13.1 17l2.9-2.9l5 1.025V21z'/%3E%3C/svg%3E");
    }

    &--desktop {
        display: none;

        @media (min-width: 990px) {
            display: flex;
        }
    }

    &--mobile {
        display: none;

        @media (max-width: 990px) {
            display: flex;
        }
    }
}

.lang {
    display: flex;
    gap: 10px;
}

.lang-el {
    width: 37px;
    height: 37px;
    border: 1px solid var(--color-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    border-radius: 50%;
    text-decoration: none;
    color: var(--color-text);

    &:hover {
        box-shadow: 0 2px 3px rgba(0 0 0 / 0.3);
    }
}

.lang-el.is-active {
    background: var(--color-text);
    color: var(--page-bg);
}

.lang-separator {
    width: 1px;
    height: 37px;
    transform: rotate(15deg);
    background: var(--color-text);
}

@media (max-width: 1260px) {
    .logo {
        margin-right: 0;
    }
    .menu,
    .phone {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .menu,
    .phone {
        font-size: 16px;
    }
}

@media (max-width: 1100px) {
    html, body {
        scroll-padding-block: 110px;
    }
    .logo {
        width: 120px;
    }
    .menu-list {
        gap: 20px;
    }
}

@media (max-width: 990px) {
    html, body {
        scroll-padding-block: 70px;
    }
    .logo {
        width: 130px;
    }
    .header-container {
        justify-content: space-between;
        gap: 10px;
        padding-right: 0;
    }
    .header-inside {
        display: flex;
        translate: -110%;
        transition: 0.2s translate;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        background: #fff;
        padding: 30px;
        box-shadow: 2px 0 30px rgba(0 0 0 / 0.3);
    }
    .menu {
        width: 100%;
        font-size: 18px;
    }
    .menu-list {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
    .menu-list-el {
        width: 100%;
    }
    .menu-list-link {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        border-bottom: 1px solid #eee;
    }
    .menu-list-el:last-child .menu-list-link {
        border-bottom: 0;
    }
    .lang {
        margin-top: auto;
    }
    .burger {
        display: flex;
    }
    body.is-menu-show {
        .header-inside {
            translate: 0;
        }

        .burger {
            position: relative;

            span:nth-of-type(1) {
                display: none;
            }

            span:nth-of-type(2),
            span:nth-of-type(3) {
                position: absolute;
                left: 50%;
                top: 50%;
                translate: -50% -50%;
                rotate: 45deg;
            }

            span:nth-of-type(3) {
                rotate: -45deg;
            }
        }
    }
}

@media (max-width: 570px) {
    .logo {
        width: 90px;
    }
}