* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

html {
    scroll-behavior: smooth;
}

html, body {
    scroll-padding-block: 130px;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Barlow", sans-serif;
    color: var(--color-text);
}

.all {
    max-width: 1960px;
    margin: 0 auto;
}