.s-gallery {
}

.s-gallery-title {
}

.s-gallery-text {
}

.m-gallery-content {}

.gallery {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 10px;
}

.gallery-el {
    aspect-ratio: 1;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 600px) {
    .gallery {
        grid-template-columns: repeat(3, 1fr);
        margin-left: -20px;
        margin-right: -20px;
    }
}