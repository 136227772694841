.s-offer {
    //background: url(../images/texture.jpg) center / cover;
    //padding: 85px 20px 150px;
}

.s-offer-title {
}

.s-offer-text {
}

//.offer-list {
//    display: flex;
//    flex-wrap: wrap;
//    justify-content: space-between;
//    gap: 40px;
//    max-width: 980px;
//    margin: 0 auto;
//}
//
//.offer-list-el {
//    width: 200px;
//    text-align: center;
//
//    img {
//        aspect-ratio: 1;
//    }
//}
//
//.offer-list-el-text {
//    margin-top: 20px;
//}
//
//.offer-list-el-img {
//}

.offer-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: 5px;
}

.offer-list li {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4z'/%3E%3C/svg%3E");
    background-size: 20px;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 30px;
    
    strong {
        font-weight: inherit;
    }
}

.offer-list li.offer-list-el-empty {
    background: none;
}