.s-two {
}

.s-two-inside {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "img content";
    margin: 0 auto;
    min-height: 800px;
}

.s-two-img {
    background: #DCE2E1;
    position: relative;
    flex-shrink: 0;
    flex: 1;
    grid-area: img;

    img,
    picture {
        height: 100%;
        position: absolute;
        right: 0;
        width: 100%;
        top: 0;
        object-fit: cover;
    }
}

.s-two-content {
    grid-area: content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 90px;
    font-size: 18px;
    line-height: 1.7;
    flex: 1;
    color: #32373a;

    p:first-child {
        margin-top: 0;
    }
}

.s-two-content strong {
}

.s-two-title {
}

.s-two--reverse {
    .s-two-inside {
        grid-template-areas: "content img";
    }
    .s-two-img-img {
        right: auto;
        left: 0;
    }
}

@media (max-width: 1200px) {
    .s-two-content {
        padding: 50px;
        font-size: 15px;
    }
}

@media (max-width: 940px) {
    .s-two-inside {
        min-height: 0;
    }
    .s-two-inside,
    .s-two--reverse .s-two-inside {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-template-areas:
                "content"
                "img";
    }
    .s-two-img {
        aspect-ratio: 16 / 12;
    }
    .s-two-content {
        display: block;
    }
}

@media (max-width: 540px) {
    .s-two-content {
        padding: 30px;
    }
}