.s-price {
}

.s-price-title {
}

.s-price-text {
}

.price-list-cnt {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}

.price-list .swiper-slide {
    display: flex;
    height: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.price-list .swiper-wrapper {
    height: auto;
}

.price-list-el {
    max-width: 350px;
    width: 100%;
    border-radius: 20px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0 0 0 / 0.07);
}

.price-list-el-title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
    box-shadow: 0 5px 20px rgba(0 0 0 / 0.1);
}

.price-list-el-img {
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-list-el-part {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eee;
    padding: 20px;
    justify-content: center;
    align-items: center;

    &:last-of-type {
        border: 0;
        padding-bottom: 40px;
    }

    span {
        font-size: 16px;
        color: #707070;
    }

    div {
        font-size: 22px;

        strong {
            font-size: 28px;
        }
    }
}

@media (max-width: 940px) {
    .price-list-cnt {
        margin-left: -20px;
        margin-right: -20px;
    }
}

@media (max-width: 470px) {
    .price-list .swiper-slide {
        padding-left: 10px;
        padding-right: 10px;
    }
    .price-list-el-part {
        padding-top: 10px;
        padding-bottom: 10px;

        div {
            font-size: 30px;
        }
    }
}