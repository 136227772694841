.popup {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 100;
    background: rgba(#000, 0.5);
    backdrop-filter: blur(2px);

    &.is-show {
        display: flex;
    }
}

.popup-container {
    line-height: 1.4;
    width: 400px;
    padding: 40px;
    border-radius: 2px;
    background: #fff;
    position: relative;
    text-align: center;
    box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);
}

.popup-title {
    margin-top: 0;
}

.popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    text-indent: -9999px;
    overflow: hidden;
    background: none;
    border: 0;
    cursor: pointer;

    &::before,
	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 30px;
		height: 1.5px;
		border-radius: 2px;
		background: #000;
		transform: translate(-50%, -50%) rotate(45deg) scale(0.9);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(-45deg) scale(0.9);
	}
}

.popup-btn {
    margin-top: 20px;
}