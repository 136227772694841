.s-opinion {
}

.s-opinion-title {
}

.s-opinion-text {
}

.opinion-list-cnt {
    padding-left: 50px;
    padding-right: 50px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.opinion-list-next {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 45%;
    translate: 0 -50%;
    width: 60px;
    height: 60px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='m13.692 17.308l-.707-.72l4.088-4.088H5v-1h12.073l-4.088-4.088l.707-.72L19 12z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
}

.opinion-list-prev {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 45%;
    translate: 0 -50%;
    width: 60px;
    height: 60px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='m6.523 12.5l3.735 3.735q.146.146.153.344q.006.198-.153.363q-.166.166-.357.168t-.357-.162l-4.382-4.383q-.243-.242-.243-.565t.243-.566l4.382-4.382q.147-.146.347-.153q.201-.007.367.159q.16.165.162.353q.003.189-.162.354L6.523 11.5h12.38q.214 0 .358.143t.143.357t-.143.357t-.357.143z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
}

.opinion-list .swiper-slide {
    display: flex;
    height: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.opinion-list .swiper-wrapper {
    height: auto;
}

.opinion-list-el {
    width: 100%;
    border-radius: 20px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0 0 0 / 0.07);
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='%23DCE2E1' d='m21.95 8.721l-.025-.168l-.026.006A4.5 4.5 0 1 0 17.5 14c.223 0 .437-.034.65-.065c-.069.232-.14.468-.254.68c-.114.308-.292.575-.469.844c-.148.291-.409.488-.601.737c-.201.242-.475.403-.692.604c-.213.21-.492.315-.714.463c-.232.133-.434.28-.65.35l-.539.222l-.474.197l.484 1.939l.597-.144c.191-.048.424-.104.689-.171c.271-.05.56-.187.882-.312c.317-.143.686-.238 1.028-.467c.344-.218.741-.4 1.091-.692c.339-.301.748-.562 1.05-.944c.33-.358.656-.734.909-1.162c.293-.408.492-.856.702-1.299c.19-.443.343-.896.468-1.336c.237-.882.343-1.72.384-2.437c.034-.718.014-1.315-.028-1.747a7 7 0 0 0-.063-.539m-11 0l-.025-.168l-.026.006A4.5 4.5 0 1 0 6.5 14c.223 0 .437-.034.65-.065c-.069.232-.14.468-.254.68c-.114.308-.292.575-.469.844c-.148.291-.409.488-.601.737c-.201.242-.475.403-.692.604c-.213.21-.492.315-.714.463c-.232.133-.434.28-.65.35l-.539.222c-.301.123-.473.195-.473.195l.484 1.939l.597-.144c.191-.048.424-.104.689-.171c.271-.05.56-.187.882-.312c.317-.143.686-.238 1.028-.467c.344-.218.741-.4 1.091-.692c.339-.301.748-.562 1.05-.944c.33-.358.656-.734.909-1.162c.293-.408.492-.856.702-1.299c.19-.443.343-.896.468-1.336c.237-.882.343-1.72.384-2.437c.034-.718.014-1.315-.028-1.747a8 8 0 0 0-.064-.537'/%3E%3C/svg%3E");
    background-position: 30px 30px;
    background-repeat: no-repeat;

    h2, h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 40px;
        text-align: left;
    }

    div {
        flex: 1;
    }

    small {
        text-align: right;
        font-weight: bold;
        font-size: inherit;
        display: block;
        margin-top: 20px;
        font-style: italic;
    }
}

@media (max-width: 570px) {
    .opinion-list-cnt {
        padding-left: 0;
        padding-right: 0;
        margin-left: -20px;
        margin-right: -20px;
    }
    .opinion-list-next,
    .opinion-list-prev {
        display: none;
    }
    .opinion-list .swiper-slide {
        padding-left: 10px;
        padding-right: 10px;
    }
}